<template>
  <div class="mt-4">
    <ul>
      <li class="text-sm font-semibold pb-4 border-b border-gray-300">
        {{ $t("choose_template") }}
      </li>
      <li
        class="
          text-sm
          flex
          justify-between
          items-center
          pt-2
          pb-6
          border-b border-gray-300
          hover:bg-gray-200
        "
        v-for="(template, index) in templates"
        :key="index"
      >
        <span class="ml-2">{{ template.name }}</span>
        <t-button
          class="mr-3 md:mr-24"
          variant="primary"
          @click="copyTemplate(template.id)"
          >{{ $t("choose") }}</t-button
        >
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getters, actions } from "@/constants/state";
import { getEndWeekDuration } from "@/utils/misc";

export default {
  methods: {
    ...mapActions({
      copyFromTemplate: actions.SCHEDULEDT_COPY_FROM_TEMPLATE_ACTION,
      getSchedulesDt: actions.SCHEDULEDT_FETCH_ACTION,
    }),
    async copyTemplate(templateId) {
      const { isCancel } = await this.$dialog.confirm(
        this.$i18n.t("are_you_sure")
      );

      if (isCancel) {
        return;
      }

      this.$emit("setLoading", true);

      try {
        const payload = {
          from: this.mainFilter.week,
          until: getEndWeekDuration(this.mainFilter.week),
          jobFilterId: this.mainFilter.jobFilterId,
        };
        await this.copyFromTemplate({
          ...payload,
          templateId,
        });
        this.$router.push("/new-rooster");
      } catch (error) {
        console.error("DEBUG: copyTemplate", error);
      }

      this.$emit("setLoading", false);
    },
  },
  computed: {
    ...mapGetters({
      templates: getters.TEMPLATES_GETTER,
      mainFilter: getters.SESSION_MAIN_FILTER_GETTER,
    }),
  },
};
</script>
